:root {
  --color-brand--2: #fc2a05;
}

.h-450-px {
  min-height: calc(100vh - 144px);
}

.search--dropdwon--list {
  max-height: calc(100vh - 400px);
  overflow: hidden auto;
}

.leaflet-top {
  z-index: 900 !important;
}

.no-image {
  width: 100px;
  height: 150px;
}

.location-popup {
  bottom: 16px !important;
}

.leaflet-popup .leaflet-popup-content-wrapper {
  border-radius: 5px;
  padding-right: 1.6rem;
}

.leaflet-popup .leaflet-popup-content {
  margin: 4px;
}

.location-popup .leaflet-popup-content-wrapper {
  border-left: 5px solid var(--color-brand--2);
}

@media (width <= 767.98px) {
  .list--of--links {
    flex-direction: column;
  }

  .list--of--security {
    flex-direction: row;
    justify-content: center;
  }

  .state--city--dropdown--section {
    flex-direction: column;
  }
}
/*# sourceMappingURL=index.a8448b6e.css.map */
